html {
  background: #e4e6e8;
  overflow: hidden;
}

body {
  font-family: "Bitter", serif;
  height: 100vh;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
